import { graphql, useStaticQuery } from 'gatsby'

export const useVisitDen = () => {
  const { content } = useStaticQuery(graphql`
    query {
      content: allContentfulMxContainer(
        filter: { contentful_id: { eq: "xAjWitxqgAsE3JSRx8nUB" } }
      ) {
        data: edges {
          node {
            sectionModules {
              moduleTitle
              moduleMisc {
                action {
                  url
                  text
                }
                description
              }
              contentSlots {
                slotTitle
                actionText
                actionUrl
                description
                isLeft
                photos {
                  title
                  description
                  file {
                    url
                  }
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                markdown {
                  raw
                }
                meta {
                  map
                  mapUrl
                  videoUrl
                }
              }
              images {
                title
                description
                file {
                  url
                }
              }
            }
            image {
              title
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `)
  return content
}

export default useVisitDen
